/* https://github.com/evgenyrodionov/flexboxgrid2 */
@import "flexboxgrid2.min.css";

@import "colors";
@import "product";
@import "fonts";
@import "cookieConsent";
@import "footer";
@import "footerLinks";
@import "calculator";
@import "templates/fiizy/src/css/navigation";
@import "templates/fiizy/src/css/contentBlocks";
@import "templates/fiizy/src/css/layout";
@import "templates/fiizy/src/css/introduction";


html,
body {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

:root {
  --fi-primary: #{$primary};
  --fi-primary-300: #{$primary300};
  --fi-secondary: #{$secondary};
  --fi-secondary-900: #{$secondary900};
  --fi-secondary-700: #{$secondary700};
  --fi-secondary-600: #{$secondary600};
  --fi-secondary-500: #{$secondary500};
  --fi-secondary-400: #{$secondary400};
  --fi-secondary-200: #{$secondary200};
  --fi-secondary-100: #{$secondary100};
  --fi-error: #{$errortint};
  --fi-button-width: 335px;
  --fi-button-margin: 10px;
  --fi-button-border-radius: 24px;
  --fi-button-color: #ffffff;
  --fi-disabled-background: #CFCFCF;
  --fi-disabled-text: #ffffff;
  --fi-process-background: #ffffff;
  --fi-process-step-background: linear-gradient(75deg, #e7a829 0%, #fbc35d);
}

audio, canvas, embed, iframe, img, object, video {
  max-width: 100%;
  vertical-align: middle;
}

.logo {
  max-width: 1536px;
  padding-top: 40px;
  padding-left: 80px;
  img {
    width: 240px;
    @media only screen and (max-width: 900px) {
      width: 168px;
    }
  }
  @media only screen and (max-width: 900px) {
    padding-left: 0;
    padding-top: 16px;
    text-align: center;
  }
}
