.home-header-container {
  background: url("https://cdn.solcredito.com/calc/cactus4000.jpg") no-repeat center bottom;
  background-size: cover;
  min-height: 95vh;
  @media only screen and (max-width: 900px) {
    background: url("https://cdn.solcredito.com/calc/cactus1000.jpg") no-repeat center bottom;
    background-size: cover;
    min-height: 100vh;
  }
}

.process .home-header-container {
  background: none;
  min-height: initial;
}

.product-container {
  display: flex;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  padding: 0 16px;
  @media only screen and (max-width: 900px) {
    position: relative;
    transform: initial;
    top: initial;
    left: initial;
    width: auto;
  }
}

.product-block {
  border-radius: 32px;
  backdrop-filter: blur(3px);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 796px;
  padding: 24px 74px;

  @media only screen and (max-width: 900px) {
    padding: 24px 16px 24px;
    margin-top: 16px;
  }

  .product-header {
    .product-icon {
      display: none;
    }
  }

  .product-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  p {
    font-size: 24px;
    letter-spacing: 0;
    margin-top: 16px;
    @media only screen and (max-width: 576px) {
      font-size: 23px;
    }
  }

  h2 {
    font-size: 40px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    @media only screen and (max-width: 576px) {
      font-size: 35px;
    }
  }

  .product-block-toggle {
    display: none;
  }

  .product-slider {
    min-width: 570px;
    @media only screen and (max-width: 900px) {
      min-width: 100%;
    }
    margin-top: 16px;
  }

  .product-button {
    display: block;
    margin-top: 30px;
    max-width: 328px;
    width: 100%;
  }

  .product-button-disclaimer {
    margin-top: 24px;

    ::before {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: ' ';
      background-size: contain;
      background-image: url('https://cdn.solcredito.com/kasowo/shield.svg');
      background-repeat: no-repeat;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
    }
  }
}
