.footer-links-container {
  background: #ff2c3c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  p {
    font-size: 14px;
    color: #fff9e8;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }

  .footer-links {
    max-width: 948px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
      text-align: center;
    }
    &-menu {
      a {
        display: inline-flex;
        color: #fff9e8;
        font-size: 14px;
        font-weight: bold;
        margin-right: 24px;
        white-space: nowrap;
        margin-top: 16px;
        text-decoration: none;

      }
    }
  }
  .footer-description {
    max-width: 388px;
    margin-right: 52px;
    @media only screen and (max-width: 900px) {
      max-width: 100%;
      margin-right: 0;
    }
  }

}
