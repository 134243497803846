.footer {
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;

  p {
    font-size: 14px;
    color: #FFFFFF;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }

  > div {
    max-width: 948px;
  }

  a {
    color: #FFFFFF;
  }

  &-partners {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    a {
      flex: 0 0 calc(25% - 40px);
      padding: 20px;
      text-align: center;
      @media only screen and (max-width: 576px) {
        flex: 0 0 calc(50% - 40px);
      }
    }
  }

  &-disclaimer {
    &.collapsable {
      transition: max-height 1s;
      max-height: 100px;
      overflow: hidden;
      position: relative;

      &.full {
        max-height: 5000px;
      }

      &:not(.full):before {
        content: "";
        display: block;
        position: absolute;
        height: 80px;
        width: 100%;
        top: 27px;
        background: linear-gradient(to bottom, rgba(34, 34, 34, 0) 0%, #222 80%);
      }
    }
  }

  #collapseDisclaimer {
    color: var(--fi-secondary, #222);
    position: absolute;
    top: 40px;
    background: #ffff00;
    border-radius: var(--fi-button-border-radius, 6px);
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    padding: 12px 47px;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
