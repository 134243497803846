@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  color: #222222;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
}

h1, h2, h3, h4, h5, p, strong {
  margin: 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 42px;
  @media only screen and (max-width: 576px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

h1, h2 {
  font-weight: bold;
}

h3 {
  font-size: 40px;
  @media only screen and (max-width: 576px) {
    font-size: 21px;
    margin-bottom: 20px;
  }
}

h4 {
  font-size: 32px;
  margin: 30px 0;
  @media only screen and (max-width: 576px) {
    margin: 20px 0;
  }
}

h5 {
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  a {
    text-decoration: none;
    font-weight: bold;
    color: #222222;
  }
}

strong, b {
  font-weight: bold;
}


