.entry-headline {
  font-size: 40px;
  @media only screen and (max-width: 576px) {
    font-size: 28px;
  }

  strong {
    font-weight: bold;
  }
}

.process, .self-service {
  .header-container, .header-image {
    background-image: none !important;
  }

  #product-selection {
    background:none !important;
  }

  .header-image {
    @media only screen and (min-width: 576px) {
      .col-sm-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: none;
      }
    }
  }
  .app-download-small-container, .how-does-it-work, .partners, #facts, #contact, #content > *:not(#app-download) {
    display: none;
  }
  .calculator-container {
    border-top: 1px solid $secondary600;
    background: var(--fi-process-background, #fff);
    padding-bottom: 100px;
    @media only screen and (max-width: 576px) {
      padding-bottom: 80px;
    }
  }

  .header-container {
    border-bottom: none;
  }

  #app-download {
    background: $bgtint100;
  }
  #content {
    padding-top: 0;
  }
  .apr {
    display: none;
  }
}

.process-form {
  margin: 22px;
}

.profile-service .calculator-container {
  padding-bottom: 0;
  background: #fff;
}

div#wave {
  text-align: center;
  margin: 20px auto;
  .dot {
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    margin-right:3px;
    background:#303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      animation-delay: -0.7s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
    height: 12px;
  }

  30% {
    transform: translateY(-15px);
  }
  50% {
    height: 8px;
  }
}
