.cookie-consent {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.5);
  padding: 8px;
  z-index: 1000;
  box-sizing: border-box;
  transform: translateX(-100%);

  p {
    font-size: 10px;
    text-align: justify;
    color: #fff;
    a {
      color: white;
      font-weight: bold;
    }
  }

  .cookie-consent-button {
    padding: 8px;
    background: #ffff00;
    border-radius: var(--fi-button-border-radius, 6px);
    text-align: center;
    font-size: 10px;
    cursor: pointer;
    font-weight: bold;
    @media only screen and (max-width: 900px) {
      margin-top: 8px;
    }
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  animation-delay: 1s;
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
